.customised-button {
    font-family: Roboto;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 23.44px;
    letter-spacing: 0.2em;
    text-align: center;
    text-transform: uppercase;
    padding: 1rem 3rem;
    border-radius: 20px;
    cursor: pointer;
}

/* Media Query for mobile devices */
@media (max-width: 800px) {
    .customised-button {
        font-size: 12px;
        line-height: 14.06px;
        letter-spacing: 0.06em;
        padding: 12px 26px;
        border-radius: 10px;
    }
}
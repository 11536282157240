.collapsible-content {
    padding: 0 18px;
    overflow: hidden;
    transition: max-height 0.2s ease-out, padding 0.2s ease-out;
    max-height: 0;
    opacity: 0;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    color: #BF7139;
}

.collapsible-content.open {
    padding: 18px 0px;
    opacity: 1;
}

.collapsible-header {
    font-family: Roboto;
    font-size: 1.5rem;
    font-weight: 700;
    color: #BF7139;
    text-transform: uppercase;
    transition: background-color 0.2s ease;
    cursor: pointer;
    width: 100%;
    text-align: left;
}

.expand,
.close {
    font-family: Roboto;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 53.76px;
    color: #BF7139;
    cursor: pointer;
}
.about .main-layout {
    width: 100%;
    /* height: calc(100vh - 142px); */
    height: 100%;
    padding-top: 3rem
}

.intro {
    width: 90%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
}

.intro .text {
    font-family: Roboto;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 2rem;
    color: #FFF7E0;
    width: 83%;
}

.intro .caption {
    font-family: Roboto;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 23.44px;
    color: #FFF7E0;
    padding-top: 40px;
}

.intro .image1 {
    width: 100%;
    height: auto
}

.mission-image {
    width: 95%;
}

.mission-section {
    width: 90%;
    margin: 0 auto;
}

.mission-section .top-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 60px;
}

.mission-section .top-section .first-row {
    max-width: 41%;
    margin-top: 60px;
}

.mission-section .top-section .h1,
.sustainable-section .h1 {
    color: #A2441B !important;
    margin-bottom: 40px;
    display: inline-block;
}

.mission-section .top-section .second-row {
    margin-top: 70px;
    margin-bottom: 70px;
}

.mission-images {
    width: 190px;
    height: 190px;
    margin-bottom: 50px;
}

.mission-section .text-content {
    width: 78%;
    margin-bottom: 50px;
}

.mission-section .text-content .h1 {
    font-size: 1.5rem !important;
    font-weight: 500 !important;
    margin-bottom: 20px;
    display: inline-block;
    color: #A2441B !important;
}

.mission-section .para,
.sustainable-section .para {
    color: #A2441B !important;
}

/* .sustainable-section .para {
    height: 144px;
    overflow: scroll;
} */

.about .top-header {
    background: #8A9B65 !important;
}

.sustainable-section .first-row,
.sustainable-section .last-row {
    width: 90%;
    margin: 0 auto;
    padding-top: 80px;
}

.sustainable-section .arrows {
    display: flex;
    align-items: end;
    justify-content: flex-end;
}

.sustainable-section .bottom-slider {
    background: #fff;
    padding: 30px;
    border-radius: 20px;
    /* width: 70%; */
}

.sustainable-section .bottom-slider .title {
    font-family: TAN-AESOP;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 35px;
    color: #A2441B;
    text-transform: uppercase;
    margin-bottom: 18px;
    width: 90%;
}

.sustainable-section .bottom-slider .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    padding-right: 43px;
}

.sustainable-section .last-row {
    padding-bottom: 80px;
}

.sustainable-section .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.sustainable-section .collapsed {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* .swiper-slide {
    display: flex;
    flex-wrap: wrap;
}

.image-wrapper,
.text-wrapper {
    flex: 1 1 100%;
    display: flex;
    align-items: stretch;
}

@media (max-width: 1200px) {

    .image-wrapper,
    .text-wrapper {
        flex: 1 1 100%;
    }
} */

/* Media Query for mobile devices */
@media (max-width: 800px) {

    .intro {
        align-items: center;
        height: auto;
        padding-top: 40px;
    }

    .intro .text {
        font-size: 1rem;
        line-height: 1.1rem;
        text-align: center;
        width: 100%;
        padding: 0px 20px;
        margin-top: 20px;
    }

    .intro .caption {
        font-size: 0.8rem;
        padding-top: 20px;
        text-align: center;
    }

    .intro .image1 {
        width: 85%;
    }

    .mission-section .top-section .first-row {
        max-width: 100%;
        margin-top: 0px;
        text-align: center;
    }

    .mission-section .para,
    .sustainable-section .para {
        padding: 0px 8%;
        line-height: 1.2rem;
    }

    .mission-section .top-section .h1,
    .sustainable-section .h1 {
        margin-bottom: 20px !important;
    }

    .sustainable-section .first-row,
    .sustainable-section .last-row {
        width: 80%;
        padding-top: 40px;
        text-align: center;
    }

    .sustainable-section .arrows {
        justify-content: center;
        margin-top: 20px;
    }

    .sustainable-section .bottom-slider {
        padding: 20px;
    }

    .sustainable-section .bottom-slider .text-wrapper {
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;
    }

    .sustainable-section .bottom-slider .title {
        width: 100%;
        padding-top: 20px;
    }
}
.program-section .title {
    font-family: Roboto;
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 60.94px;
    color: #FFF5D9;
    text-transform: uppercase;
    display: flex;
    width: 90%;
    justify-content: flex-start;
}

.slider-title {
    font-family: Roboto;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #FFF5D9;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.slider-date {
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 300;
    line-height: 21.09px;
    color: #FFF5D9;
    text-transform: uppercase;
}

.slider-location {
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 700;
    line-height: 21.09px;
    color: #FFF5D9;
    text-transform: uppercase;
}

.slide-wrapper {
    width: 300px;
    height: 430px;
    background-size: cover;
    background-position: center;
    position: relative;
    color: white;
    display: flex;
    align-items: end;
    justify-content: flex-start;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7)
}

.arrow-left {
    position: absolute;
    left: 7%;
    top: 50%;
    transform: translateY(-50%);
    height: 40px;
    width: 30px;
    cursor: pointer
}

.arrow-right {
    right: 7%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 40px;
    width: 30px;
    cursor: pointer;

}

.text-wrapper-program {
    padding: 20px 3px 20px 25px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

/* Media Query for mobile devices */
@media (max-width: 800px) {
    .program-section .title {
        font-size: 1.6rem;
        width: auto;
        text-align: center;
    }

    .slider-title {
        font-size: 1rem;
        padding-bottom: 10px;
    }

    .slider-date {
        font-size: 0.8rem;
    }

    .slider-location {
        font-size: 0.8rem;
        line-height: 1rem
    }

    .slide-wrapper {
        height: 260px;
        background-size: cover;
        background-position: center;
        position: relative;
        color: white;
        display: flex;
        align-items: end;
        justify-content: flex-start;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
        opacity: 1;
        margin: 0 40px;
        transition: transform 0.5s ease, opacity 0.5s ease
    }

    .arrow-left {
        left: 12%;
        z-index: 2,
    }

    .arrow-right {
        right: 12%;
        z-index: 2
    }

    .text-wrapper-program {
        padding: 20px 0px 10px 10px
    }
}
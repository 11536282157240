@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@font-face {
  font-family: 'TAN-AESOP';
  src: url('./fonts/TAN-AESOP/TAN - AESOP.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Aktiv Grotesk';
  src: url('./fonts/Aktiv Grotesk/AktivGrotesk-Medium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body,
html,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background: #BE6F3F;
}

.main-layout {
  height: 100vh;
  background: #BE6F3F url('./images/Tanweer Pattern.png') no-repeat center center;
  background-size: cover;
  width: 100%;
}

.h1 {
  font-family: Roboto;
  font-size: 2rem;
  font-weight: 700;
  line-height: 34px;
  color: #731D14;
  text-transform: uppercase;
  margin-bottom: 26px;
}

.para {
  font-family: Roboto;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: #731D14;
}

.header {
  display: flex;
  align-items: center;
  padding-left: 63px;
  background: transparent;
  margin-top: 26px;
  border-bottom: none;
  margin-bottom: 100px;
}

.header-mobile {
  padding-left: 59px !important;
}

.header-logo {
  height: 47px;
}

.header-logo-mobile {
  height: 31px;
}

.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 100px);
  margin-top: -90px;
}

.rotating-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.center {
  position: relative;
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 40px 0px;
}

.center-mobile {
  width: 250px;
  height: 250px;
}

.breathe-in {
  color: #FFF5D9;
  text-align: center;
  font-family: TAN-AESOP;
  font-size: 34px;
  font-weight: 400;
  line-height: 46.34px;
}

.breathe-in-mobile {
  font-size: 24px;
  line-height: 32.71px;
}

.journey-text {
  font-family: TAN-AESOP;
  font-size: 24px;
  font-weight: 400;
  color: #FFF5D9;
  text-transform: uppercase;
}

.journey-text-mobile {
  font-size: 14px;
  line-height: 19.08px;
}

.number-style {
  font-family: TAN-AESOP;
  font-size: 35px;
  font-weight: 400;
  line-height: 47.71px;
  color: #FFF5D9;
}

.number-style-mobile {
  font-size: 20px;
  line-height: 27.26px;
  letter-spacing: -0.01em;
}

.time-style {
  font-family: Aktiv Grotesk;
  font-size: 20px;
  font-weight: 700;
  line-height: 25.6px;
  color: #A2441B;
  text-transform: uppercase;
}

.time-style-mobile {
  font-size: 12px;
  line-height: 15.36px;
}

.circle-outline {
  width: 100%;
  height: auto;
}

.logo-icon {
  position: absolute;
  width: 300px;
  height: auto;
  z-index: 1;
}

.orbit {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotating-circle {
  position: absolute;
  width: 53px;
  height: 53px;
  top: 50%;
  left: 50%;
  margin-top: -223.5px;
  /* Move to the top edge of the circle outline */
  margin-left: -26.5px;
  /* Center horizontally on the outline */
  transform-origin: center 223.5px;
  /* Rotate around the center of the circle outline */
  animation: rotate 10s linear infinite;
}

.rotating-circle-mobile {
  width: 43px;
  height: 43px;
  margin-top: -145.5px;
  /* Move to the top edge of the circle outline */
  margin-left: -21.5px;
  /* Center horizontally on the outline */
  transform-origin: center 145.5px;
  animation: rotate 10s linear infinite;
}

.floating-button {
  font-family: Roboto;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 23.44px;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
  padding: 1rem 1.2rem;
  cursor: pointer;
  color: #FFF5D9;
  background: #731D14;
  position: fixed;
  top: 40vh;
  right: 0px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  z-index: 10;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bottom-text {
  text-align: center;
  color: white;
}

.countdown {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.countdown-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
}

.countdown-column div:first-child {
  font-size: 24px;
  font-weight: bold;
}

.loading-message {
  font-family: TAN-AESOP;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF5D9;
  font-size: 24px;
  text-align: center;
  background: #BE6F3F;
  font-size: 34px;
  font-weight: 400;
  line-height: 52px;
  text-transform: uppercase;
  padding: 0px 63px
}

.loading-message-mobile {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  padding: 0px 47px;
}

.mobile .rotating-container {
  max-width: 100%;
  max-height: 100%;
}

.mobile .logo-icon {
  width: 70%;
}

.colon-column {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -29px;
}

.colon-style {
  color: #FFF5D9;
  font-family: TAN-AESOP;
  font-size: 35px;
  font-weight: 400;
  line-height: 47.71px;
}

.colon-style-mobile {
  font-size: 20px;
  line-height: 27.26px;
  letter-spacing: -0.01em;
}

/* Zoom In */
.zoom-in {
  animation: zoomIn 0.5s ease-out forwards;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Zoom Out */
.zoom-out {
  animation: zoomOut 10s ease-out forwards;
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}

.ant-popover-title {
  text-align: center;
}

.ant-popover-inner-content {
  display: flex;
  justify-content: center;
}

.subscribe-popup-head .custom-input:focus,
.subscribe-popup-head .custom-input:hover,
.subscribe-popup-head .custom-input:active {
  background: #FFEBB9;
  border-color: initial;
  box-shadow: none;
}

.subscribe-popup-head .custom-button:focus,
.subscribe-popup-head .custom-button:hover,
.subscribe-popup-head .custom-button:active {
  background: #9e4b19 !important;
}

.footer .bottom-section .custom-button:focus,
.footer .bottom-section .custom-button:hover,
.footer .bottom-section .custom-button:active {
  background: #9e4b19 !important;
}

.react-share__ShareButton {
  padding-right: 20px !important;
}

.ant-modal .ant-modal-content {
  background-color: #ffffff00;
  padding: 0px;
}

.ant-message-notice-content {
  padding: 16px !important; 
  background-color: #fff5d9 !important; 
  border-radius: 8px !important; 
  color: #731D14 !important;
  font-size: 1rem;
}

/* Media Query for mobile devices */
@media (max-width: 800px) {
  .main-layout {
    height: calc(100vh - 84px);
    /* Adjust as needed */
  }

  .h1 {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .para {
    font-size: 0.9rem;
  }

  .floating-button {
    width: 20%;
    line-height: 0.7rem;
    text-align: left;
    padding: 0.9rem 1.2rem;
  }
}
.landing .main-layout {
    height: 100% !important;
    width: 100% !important;
    padding: 3rem 0px;
}

.landing-banner {
    height: calc(100vh - 141px);
    width: 100%;
    display: flex;
    justify-content: center;
}

.landing-banner .image-container {
    position: absolute;
    width: 100%;
}

.landing-banner .image {
    height: calc(100vh - 141px);
    width: 100%;
    object-fit: cover;
}

.landing-banner .content {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    bottom: 45%;
}

.landing-banner .title {
    font-family: TAN-AESOP;
    font-size: 2rem;
    font-weight: 400;
    line-height: 75.25px;
    text-align: center;
    color: #FFF5D9;
    text-transform: uppercase;
}

.landing-banner .button {
    background-color: #A2441B;
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 500;
    line-height: 23.44px;
    letter-spacing: 0.2em;
    text-align: center;
    color: #FFF5D9;
    text-transform: uppercase;
    padding: 1rem 1.9rem;
    border-radius: 20px;
    margin: 4rem 0px;
    cursor: pointer;
}

.landing-banner .date {
    font-family: TAN-AESOP;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 39.53px;
    letter-spacing: 0.2em;
    text-align: center;
    color: #FFF5D9;
}

.landing-banner .caption {
    font-family: Roboto;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 25.78px;
    letter-spacing: 0.2em;
    text-align: center;
    color: #FFF5D9;
    margin-top: 8px;
}

.middle-tabs {
    background-color: #FFF5D9;
    padding: 2rem 0px 1.9rem;
}

.middle-tabs .text {
    font-family: TAN-AESOP;
    font-size: 2rem;
    font-weight: 400;
    text-align: left;
    color: #EFBB6B;
    text-transform: uppercase;
}

.middle-tabs .pattern {
    height: 24px;
}

.middle-tabs .space {
    display: flex;
    padding: 0px 10%;
    justify-content: space-between;
    align-items: center;
}

.landing-content {
    height: 100vh;
    background: #ffebb9 url('../../images/Home/landing-Pattern.png') no-repeat center center;
    background-size: cover;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-content .image {
    width: 80%;
    border-radius: 100%;
}

.landing-content .title {
    font-family: Roboto;
    font-size: 2rem;
    font-weight: 700;
    line-height: 34px;
    color: #731D14;
    text-transform: uppercase;
    margin-bottom: 43px;
    display: flex;
}

.landing-content .paragraph {
    font-family: Roboto;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6rem;
    text-align: left;
    color: #731D14;
}

.subscribe-popup {
    height: 351px;
    background: #fff5d9 url('../../images/subscribe_pattern.png');
    background-size: cover;
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: 7px 7px 25px 10px #00000040;
    border-radius: 20px;
    bottom: 15%;
    position: relative;
    padding: 20px 30px 20px 40px;
    flex-direction: column;
}

.subscribe-h1 {
    font-family: Roboto;
    font-size: 2rem;
    font-weight: 700;
    line-height: 42.19px;
    color: #731D14;
    text-transform: uppercase;
    margin-top: -32px;
}

.subscribe-paragraph {
    font-family: Roboto;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 25.78px;
    text-align: left;
    color: #BF7139;
    text-transform: uppercase;
}

.subscribe-popup .custom-input {
    background-color: #FFEBB9;
}

.close-button-container {
    text-align: right;
    top: -50px;
    position: relative;
}

.close-button-image {
    height: 26px;
    width: 26px;
    cursor: pointer;
}

.subscribe-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
}

.subscribe-button .container {
    width: 90%;
}

.program-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    flex-direction: column;
}

.activities-section .content {
    /* height: calc(100vh - 50px); */
    height: 100%;
    background-color: #FFF5D9;
}

.activities-section .image {
    width: 100%;
    /* height: calc(100vh - 50px); */
    height: 100%;
    object-fit: cover;
}

.button-component-wrapper {
    margin: 40px;
}

.left-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    padding: 4rem 0px;
}

.left-content-text {
    width: 67%;
    padding-left: 5rem;
}

.activities-section .button-component-wrapper2 {
    margin: 40px 0px 80px;
}

.activities-section .slider-image {
    width: 100%;
    border-radius: 1rem;
}

.slider {
    margin-left: -7%;
    width: 107%;
    margin-top: 30px;
}

.image-title-slide {
    font-family: Roboto;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 25.78px;
    text-align: center;
    color: #FFF5D9;
    text-transform: uppercase;
    position: absolute;
    top: 39%;
    right: 0px;
    left: 0px;
}

.tickets-available-soon-button {
    font-family: Roboto;
    /* font-size: 0.9rem; */
    font-size: 0.6rem;
    font-weight: 500;
    line-height: 23.44px;
    letter-spacing: 0.2em;
    text-align: center;
    text-transform: uppercase;
    padding: 1rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    color: #FFF5D9;
    background: #731D14;
}

/* Media Query for mobile devices */
@media (max-width: 800px) {
    .landing-banner {
        height: calc(100vh - 96px);
    }

    .landing-banner .image {
        object-position: 32% center;
        height: calc(100vh - 96px);
    }

    .landing-banner .title {
        font-size: 36px;
        line-height: 49.07px;
        word-break: normal;
        width: 50%;
    }

    .landing-banner .content {
        justify-content: center;
        bottom: -4%;
    }

    .landing-banner .date {
        font-size: 22px;
        line-height: 29.99px;
        letter-spacing: 0.2em;
    }

    .landing-banner .button {
        font-size: 12px;
        line-height: 14.06px;
        letter-spacing: 0.06em;
        padding: 12px 26px;
        border-radius: 10px;
        margin: 46px 0px;
    }

    .landing-banner .caption {
        font-size: 12px;
        line-height: 14.06px;
        letter-spacing: 0.2em;
    }

    .middle-tabs .text {
        font-size: 0.7rem;
        line-height: 19.08px;
    }

    .middle-tabs {
        padding: 16px 0px 14px;
    }

    .middle-tabs .pattern {
        height: 11px;
    }

    .landing-content {
        text-align: center;
        height: 100%;
        padding: 40px 0px;
    }

    .landing-content .image {
        width: 70%;
    }

    .landing-content .content-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px 3rem;
    }

    .landing-content .title {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 20px;
        margin-top: 34px;
    }

    .landing-content .paragraph {
        font-size: 18px;
        line-height: 24.35px;
        letter-spacing: 0.01em;
        text-align: center;
    }

    .subscribe-popup {
        /* bottom: 25%; */
        /* width: 77%; */
        height: auto;
        padding: 40px 30px 40px 40px;
    }

    .close-button-image {
        height: 18px;
        width: 18px;
    }

    .subscribe-h1 {
        font-size: 1.5rem;
        line-height: 29.19px;
        margin-top: 10px;
    }

    .close-button-container {
        top: -10px;
    }

    .subscribe-paragraph {
        font-size: 0.9rem;
    }

    .custom-input-mobile {
        border-radius: 10px;
        background-color: #FFEBB9;
        padding-right: 100px;
        color: #C06F39;
        padding: 14px 11px;
        border: none;
        padding-right: 26px;
        padding-left: 26px;
        margin-top: 20px;
    }

    .custom-input-mobile:focus,
    .custom-input-mobile:hover,
    .custom-input-mobile:active {
        background: #FFEBB9;
        border-color: initial;
        box-shadow: none;
    }

    .custom-button-mobile {
        border-radius: 10px;
        background-color: #BE6F3F !important;
        color: #FFF7E0 !important;
        border: none;
        padding: 25px 0px;
        width: 100%;
        margin-top: 20px;
    }

    .custom-button-mobile:hover,
    .custom-button-mobile:focus,
    .custom-button-mobile:active {
        background-color: #BE6F3F !important;
        color: #FFF7E0 !important;
        border-color: none;
        box-shadow: none;
    }

    .button-component-wrapper {
        margin: 50px;
    }

    .activities-section .image {
        height: 171px;
        object-fit: cover;
        object-position: 50% 71%;
    }

    .left-content {
        margin-top: 30px;
        display: flex;
        align-items: center;
    }

    .left-content-text {
        width: 79%;
        padding-left: 2rem;
        padding-right: 2rem;
        text-align: center;
    }

    .slider {
        margin-left: 27%;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .program-section {
        height: 100% !important;
        padding: 60px 0px;
    }

    .activities-section .content {
        height: 100%;
    }

    .activities-section .button-component-wrapper2 {
        margin: 20px 0px 40px;
    }

    .landing .activities-section {
        width: 100%;
        overflow: hidden;
    }
}
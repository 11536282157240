.contact {
    background: #FFFDF7;
}

.contact-wrapper {
    /* height: calc(100vh - 142px); */
    width: 100%;
}

.contact .contact-wrapper .content {
    display: flex;
    flex-direction: column;
    margin-left: 12%;
    margin-right: 10%;
    margin-top: 80px;
}

.contact .content .title {
    font-family: TAN-AESOP;
    font-size: 2rem;
    font-weight: 400;
    line-height: 3rem;
    color: #BF7139;
    text-transform: uppercase;
    width: 80%;
    margin-bottom: 30px;
}

.contact .content .description {
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3rem;
    color: #BF7139;
}

.faq-section {
    margin-left: 5%;
    margin-right: 15%;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
}

.faq-section .tabs {
    margin-top: 30px;
    margin-bottom: 50px;
}

.faq-section .tab {
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 28.13px;
    color: #731D14 !important;
    text-transform: uppercase;
    margin-right: 1rem;
    cursor: pointer;
    /* word-break: keep-all; */
}

.collapsible {
    border-top: 1px solid #A34411;
    padding: 12px 6px;
}

.collapsible .para {
    width: 50%;
}

.contact .swiper-button-prev,
.contact .swiper-button-next {
  position: absolute;
  top: 50%; /* Center vertically */
  transform: translateY(50%); /* Adjust to center */
  z-index: 10; /* Ensure they are above other elements */
  background: rgba(255, 255, 255, 0.8); /* Optional background */
  border-radius: 50%; /* Optional rounded buttons */
  width: 20px; /* Width of the button */
  height: 20px; /* Height of the button */
}

.contact .swiper-button-prev {
  left: -20px; /* Position to the left */
}

.contact .swiper-button-next {
  right: -20px; /* Position to the right */
}

.contact .swiper-button-next:after, .contact .swiper-rtl .contact .swiper-button-prev:after {
    font-size: 20px !important;
    color: #731d14 !important;
}

.contact .swiper-button-prev:after, .contact .swiper-rtl .contact .swiper-button-next:after{
    font-size: 20px !important;
    color: #731d14 !important;
}

/* Media Query for mobile devices */
@media (max-width: 800px) {
    .contact .swiper-button-prev {
        left: -20px; /* Position to the left */
      }
      
      .contact .swiper-button-next {
        right: -20px; /* Position to the right */
      }
    .contact .contact-wrapper .mobile-view {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .contact .content .title {
        font-size: 1.6rem;
        width: 100%;
        text-align: center;
        line-height: 2rem;
    }

    .contact .content .description {
        font-size: 0.9rem;
        line-height: 1rem;
    }


    .faq-section .tab {
        font-size: 0.9rem;
        margin-right: 1rem;
    }

    .collapsible-header {
        font-size: 1.2rem;
        width: 90%;
    }

    .collapsible .para {
        width: 90%;
    }

    .faq-section {
        margin-left: 7.5%;
        margin-right: 7.5%;
    }

    .faq-section .swiper-slide {
        width: auto !important
    }


}
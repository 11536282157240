.activity {
    background: #FEF7E3;
}

.activity .top_banner {
    height: calc(100vh - 142px);
    position: relative;
}

.activity .top_banner .content {
    position: absolute;
    bottom: 10%;
    display: flex;
    flex-direction: column;
    width: 54%;
    left: 5%;
}

.activity .top_banner .image-container img {
    height: calc(100vh - 142px);
    width: 100%;
    object-fit: cover;
}

.activity .content .title {
    font-family: TAN-AESOP;
    font-size: 2rem;
    font-weight: 400;
    line-height: 3rem;
    color: #FEF7E3;
    text-transform: uppercase;
    width: 80%;
    margin-bottom: 30px;
}

.activity .content .description {
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3rem;
    color: #FEF7E3;
}

.middle_section,
.camping_section {
    margin-left: 5%;
    padding: 82px 0px;
}

.dark-arrows {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-right: 5%;
    padding-bottom: 20px;
}

.top-drag-slider .title {
    font-family: Roboto;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 42.19px;
    color: #731D14;
    text-transform: uppercase;
    padding-top: 24px;
    /* margin-bottom: 40px; */
}

.top-drag-slider .description,
.top-drag-slider .checkouttime {
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3rem;
    color: #731D14;
    padding-bottom: 20px;
}

.top-drag-slider .price {
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.3rem;
    color: #731D14;
    padding-bottom: 15px;
}

.top-drag-slider .price-des {
    font-family: Roboto;
    font-size: .8rem;
    font-weight: 500;
    line-height: 1rem;
    color: #BE6F3F;
    padding-bottom: 15px;
}

.top-drag-slider .button {
    font-family: Roboto;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 23.44px;
    letter-spacing: 0.2em;
    text-align: center;
    text-transform: uppercase;
    padding: 1rem 3rem;
    border-radius: 20px;
    cursor: pointer;
    color: #FFF5D9;
    background: #A2441B;
    width: 85%;
}

.camping_section {
    margin-right: 5%;
}

.camping_section .top-drag-slider {
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
}

.camping_section .top-drag-slider .title {
    color: #BE6F3F;
}

.camping_section .top-drag-slider .description,
.camping_section .top-drag-slider .checkouttime {
    color: #BE6F3F;
}

.camping_section .top-drag-slider .price {
    color: #BE6F3F;
    font-weight: 400;
    padding-bottom: 0px;
}

.camping_section .top-drag-slider .button {
    color: #FFF;
    width: 100%;
}

.bottom_section {
    height: 100%;
    background-color: #BE6F3F;
    padding: 82px 5%;
}

.bottom_section .h1 {
    color: #FEF7E3;
}

.bottom_section .top-drag-slider {
    background: #FFF5D9;
    padding: 30px;
    border-radius: 20px;
}

.bottom_section .top-drag-slider .button {
    color: #FFF;
    width: 100%;
}

/* .middle_section .top-drag-slider .title {
    height: 100px;
} */

.bottom_section .description {
    height: 248px;
}

.activity .middle_section .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 109px;
}

.activity .middle_section .description {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 103px;
}

.activity .camping_section .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 109px;
}

.activity .camping_section .description {
    /* display: -webkit-box; */
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 144px;
}

.activity .camping_section .checkouttime {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 61px;
}

.activity .bottom_section .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 109px;
}

.activity .bottom_section .description {
    display: -webkit-box;
    -webkit-line-clamp: 12;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 248px;
}

.activity .main-layout .marketplace {
    margin-left: 7.5%;
    margin-right: 7.5%;
    padding: 82px 0px;
}

/* Media Query for mobile devices */
@media (max-width: 800px) {

    .activity .top_banner .content {
        width: 90%;
        left: 5%;
        top: 40%;
        text-align: center;
    }

    .activity .content .title {
        font-size: 1.6rem;
        width: 100%;
        text-align: center;
        line-height: 2rem;
    }

    .activity .content .description {
        font-size: 0.9rem;
        line-height: 1rem;
    }

    .middle_section,
    .camping_section {
        margin-left: 7.5%;
        padding: 42px 0px;
        margin-right: 7.5%;
    }

    .middle_section .top-slide-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .top-drag-slider .title {
        padding-bottom: 20px;
    }

    .top-drag-slider .description {
        width: 100%;
        padding: 0px 10%;
        padding-bottom: 20px;
        display: block;
    }

    .dark-arrows {
        margin-right: 0%;
    }

    .bottom_section {
        padding: 42px 7.5%;
    }

    .camping_section .description {
        height: 180px !important;
    }

    .bottom_section .description {
        height: 352px;
    }
}